import React from "react";
import TwitterIcon from "../../../assets/icons/front-pages/twitter.svg";
import InstagramIcon from "../../../assets/icons/front-pages/instagram.svg";
import LinkedinIcon from "../../../assets/icons/front-pages/linkedin.svg";
import Badge from "../../ui/badge";
import Router from "next/router";
import Link from "next/link";
import { AppBtn } from "../../ui/buttons";
import Portal from "../../portal";
import { useModals } from "../../hooks/useModals";
import LazyImage from "../../lazy-image";
import dynamic from "next/dynamic";
import { toAppUrl } from "@/assets/js/utils/functions";

const FrontPagesVideoPlayer = dynamic(() => import("../commons/video-player"), { ssr: true });

const FrontPagesFooter = () => {
  const { modals, toggleModal } = useModals(["video"]);

  return (
    <footer className="body-padding body-y-padding bg-primary-900 relative">
      <div className="container">
        <div className="flex items-stretch justify-between flex-col md:flex-row border-b border-grey-border border-opacity-30 mb-10 sm:mb-20 pb-10 sm:pb-15">
          <div className="flex flex-col justify-between items-start md:mr-10">
            <h6 className="text-2lg sm:text-2xl lg:text-[27px] text-white font-bold font-display max-w-md !leading-tight hidden md:block">
              Catlog is really easy to use. But, don&apos;t take our word for it, watch Silas setup a Catlog Store in{" "}
              <span className="text-accent-yellow-500">2 minutes.</span>
            </h6>
            <h6 className="text-2lg sm:text-2xl lg:text-[27px] text-white font-bold font-display max-w-md !leading-tight block md:hidden">
              Catlog is really easy to use. <br /> <span className="text-accent-yellow-500">See for yourself!</span>
            </h6>
            <AppBtn
              size="lg"
              className="mt-5 !font-semibold w-auto hidden md:!inline-flex"
              color="white"
              href={toAppUrl("app/sign-up")}
            >
              Ready? Get started
              {/* prettier-ignore */}
              <svg viewBox="0 0 12 12" fill="none" className="ml-1.5 w-3 transform transition-all ease-out duration-100 group-hover:translate-x-0.5">
                <path d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z" fill="currentColor" strokeWidth={1.5} />
              </svg>
            </AppBtn>
          </div>
          <figure className="w-full bg-white md:max-w-sm rounded-15 mt-7.5 sm:mt-9 md:mt-0 overflow-hidden safari-overflow-fix">
            <div className="w-full pt-[56%] relative">
              <LazyImage
                src="https://res.cloudinary.com/catlog/image/upload/v1675646206/front-pages/silas-website.webp"
                alt=""
                className="w-full h-full object-cover absolute top-0 left-0"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-20 flex items-center justify-center">
                <button className="text-white no-outline play-button-ripples" onClick={() => toggleModal("video")}>
                  <span className="relative z-50 flex items-center px-2.5 py-1.75 bg-accent-yellow-main rounded-30">
                    {/* prettier-ignore */}
                    <svg className="w-4.5" viewBox="0 0 40 40" fill="none">
                      <path d="M15.168 20.0005V17.5338C15.168 14.3505 17.418 13.0671 20.168 14.6505L22.3013 15.8838L24.4346 17.1171C27.1846 18.7005 27.1846 21.3005 24.4346 22.8838L22.3013 24.1171L20.168 25.3505C17.418 26.9338 15.168 25.6338 15.168 22.4671V20.0005Z" stroke="currentColor" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M19.9987 36.6663C29.2034 36.6663 36.6654 29.2044 36.6654 19.9997C36.6654 10.7949 29.2034 3.33301 19.9987 3.33301C10.794 3.33301 3.33203 10.7949 3.33203 19.9997C3.33203 29.2044 10.794 36.6663 19.9987 36.6663Z" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span className="ml-2 text-1xs font-medium inline-block">Play this video</span>
                  </span>
                </button>
              </div>
            </div>
          </figure>
        </div>
        <div className="flex items-start justify-between flex-wrap">
          <div className="flex flex-col mr-8 mb-12.5">
            <figure>
              <img src="/images/logos/new-white.svg" alt="Catlog logo light" height={28} className="h-7" />
            </figure>
            <p className="text-sm text-grey-fields-100 mt-3.75 max-w-[295px] md:max-w-[285px]">
              Take orders via chat, manage your business & get paid seamlessly with Catlog
            </p>
            <figure className="mt-5 sm:mt-6.25">
              <img
                src="/images/download-badge.svg"
                alt="Catlog app download banner"
                className="h-11.25 sm:h-12.5 lg:h-15"
              />
            </figure>
          </div>
          <div className="flex items-start flex-wrap justify-around flex-1 max-w-[700px] md:min-w-[450px]">
            {footerLinks.map((links, _) => (
              <ul className="flex flex-col flex-1 mr-8 pb-6.25" key={_}>
                <li className="uppercase text-grey-border-dark text-xs md:text-1xs lg:text-sm mb-4.5 sm:mb-6.25">
                  {links.title}
                </li>

                {links.links.map((link, index) => (
                  <li key={index} className="mb-2.5 md:mb-3.75 flex items-center">
                    {link?.path?.includes("http") && (
                      <a
                        href={link.path}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-grey-fields-100 hover:text-accent-yellow-500 font-medium text-1sm md:text-base whitespace-nowrap cursor-pointer"
                      >
                        {link.title}
                      </a>
                    )}

                    {!link?.path?.includes("http") && (
                      <Link href={link.path ?? ""}>
                        <a className="text-grey-fields-100 hover:text-accent-yellow-500 font-medium text-1sm md:text-base whitespace-nowrap cursor-pointer">
                          {link.title}
                        </a>
                      </Link>
                    )}

                    {/* {link?.isComingSoon && (
                      <Badge size="sm" text="SOON" color="orange" className="ml-1" greyBg={false} />
                    )} */}
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
        <div className="pt-5 border-t border-grey-border border-opacity-30 mt-8 sm:mt-10 flex items-center justify-between flex-wrap">
          <span className="text-grey-border-dark text-sm font-medium">
            &copy; {new Date().getFullYear()} Catlog Inc.
          </span>
          <div className="flex items-center space-x-6">
            <a
              href="https://twitter.com/catlogshop"
              target="_blank"
              rel="noopener noreferrer"
              className=" text-grey-border-dark hover:text-accent-yellow-500 inline-flex border border-grey-border-dark items-center justify-center rounded-full h-8 w-8"
              aria-label="Catlog's twitter"
            >
              <div className="w-1/2">
                <TwitterIcon />
              </div>
            </a>
            <a
              href="https://instagram.com/catlogshop"
              target="_blank"
              rel="noopener noreferrer"
              className="text-grey-border-dark hover:text-accent-yellow-500 inline-flex border border-grey-border-dark items-center justify-center rounded-full h-8 w-8 "
              aria-label="Catlog's instagram"
            >
              <div className="w-1/2">
                <InstagramIcon />
              </div>
            </a>
            <a
              href="https://linkedin.com/company/myshopcatlog"
              target="_blank"
              rel="noopener noreferrer"
              className=" text-grey-border-dark hover:text-accent-yellow-500 inline-flex border border-grey-border-dark items-center justify-center rounded-full h-8 w-8 "
              aria-label="Catlog's linkedin"
            >
              <div className="w-1/2">
                <LinkedinIcon />
              </div>
            </a>
          </div>
        </div>
      </div>
      <Portal>
        <FrontPagesVideoPlayer show={modals.video} toggle={() => toggleModal("video")} ytVideoId="Bv6MSgtXtHY" />
      </Portal>
    </footer>
  );
};

const footerLinks = [
  {
    title: "Products",
    links: [
      {
        title: "Store Links",
        path: "/store-links",
      },
      {
        title: "Orders & Customers",
        path: "/orders-and-customers",
      },
      {
        title: "Payments & Invoices",
        path: "/payments",
      },
      {
        title: "Invoice Links",
        path: "/orders-and-customers",
      },
      {
        title: "Find with Catlog",
        path: "/findwithcatlog",
      },
      {
        title: "Deliveries",
        path: "/deliveries",
      },
    ],
  },
  {
    title: "Learn",
    links: [
      {
        title: "Why Catlog",
        path: "/why-catlog",
      },
      {
        title: "About",
        path: "/about",
      },
      {
        title: "Pricing",
        path: "/pricing",
      },
      {
        title: "Blog",
        path: "/blog",
      },
      {
        title: "Help Articles",
        path: "/support",
      },
      {
        title: "Privacy Policy",
        path: "https://catlog-help-center.notion.site/Privacy-Policy-2b9b7ac5613b48f7b53016a597a40680",
      },
    ],
  },
  {
    title: "What can I sell?",
    links: [
      {
        title: "Food & Drinks",
        path: null,
      },
      {
        title: "Fashion Items",
        path: null,
      },
      {
        title: "Gadgets",
        path: null,
      },
      {
        title: "Beauty & Makeup",
        path: null,
      },
      {
        title: "Physical Products",
        path: null,
      },
    ],
  },
];

export default FrontPagesFooter;
