import dynamic from "next/dynamic";
import Head from "next/head";
import React, { ReactNode, useEffect, useState } from "react";
import PagesLoaderContext from "../../contexts/page-loader-context";
import useObservers from "../hooks/useObservers";
import SEOTags, { PageTags } from "../seo-tags";
import useSearchParams from "../hooks/useSearchParams";
import { useLocalObject } from "../hooks/useLocalState";
import { getCookie } from "@/assets/js/utils/functions";

const FrontPagesLoader = dynamic(() => import("./loader"), { ssr: true });

interface Props {
  children?: ReactNode;
  tags?: PageTags;
  colors?: { main: string; pastel: string };
  hideAnimation?: boolean;
}

const FrontPagesLayout: React.FC<Props> = ({
  children,
  tags,
  colors = {
    main: "#332089",
    pastel: "#F7F5FF",
  },
  hideAnimation = false,
}) => {
  const { utm_campaign, utm_source, utm_medium, gclid } = useSearchParams([
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "gclid",
  ]);
  const [soureAdStorage, setSourceAdStorage] = useLocalObject<{
    utm_campaign?: string;
    utm_source?: string;
    utm_medium?: string;
    gclid?: string;
    fbp?: string;
    fbc?: string;
  }>("sourceAd", {});
  const contextData = PagesLoaderContext.useContainer();
  const { animationHasPlayed, setAnimationHasPlayed } = hideAnimation
    ? { animationHasPlayed: true, setAnimationHasPlayed: () => {} }
    : contextData;

  useObservers({ hasLoaded: animationHasPlayed });
  useEffect(() => {
    document.documentElement.style.setProperty("--page-color", colors.main);
    document.documentElement.style.setProperty("--page-pastel", colors.pastel);

    document.documentElement.style.setProperty("--primary-500", "51, 32, 137");
    document.documentElement.style.setProperty("--accent-yellow-500", "239, 148, 15");
  }, []);

  useEffect(() => {
    const sourceAdObj = {};

    if (utm_campaign) sourceAdObj["utm_campaign"] = utm_campaign;
    if (utm_medium) sourceAdObj["utm_medium"] = utm_medium;
    if (utm_source) sourceAdObj["utm_source"] = utm_source;
    if (gclid) sourceAdObj["gclid"] = gclid;

    //only update source ad value if theres at least one of the parameters
    if (Object.values(sourceAdObj).filter((v) => !!v).length > 0) {
      setSourceAdStorage(sourceAdObj);
    }

    let retries = 5;
    const interval = setInterval(() => {
      const fbp = getCookie("_fbp");
      const fbc = getCookie("_fbc");

      if (fbp || fbc || retries <= 0) {
        sourceAdObj["fbp"] = fbp;
        sourceAdObj["fbc"] = fbc;

        if (fbp) sourceAdObj["fbp"] = fbp;
        if (fbc) sourceAdObj["fbc"] = fbc;
        if (fbp || fbc) setSourceAdStorage(sourceAdObj);
        clearInterval(interval);
      }

      retries -= 1;
    }, 300); // Check every 300ms
  }, [utm_campaign, utm_source, utm_medium, gclid]);

  return (
    <>
      {tags && <SEOTags tags={tags} />}
      {!hideAnimation && <FrontPagesLoader {...{ animationHasPlayed: animationHasPlayed, setAnimationHasPlayed }} />}
      <div className={`${animationHasPlayed ? "" : "hidden"}`}>{children}</div>
    </>
  );
};

export default FrontPagesLayout;
